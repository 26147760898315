//将所有接口统一集中在apis文件中，然后暴露出去，方便后期所有人维护、更改

import http from './http'  //引入封装好的axios请求方式

//所有的接口字典
const apis = {
  login: '/api/user/login',  //登录接口
  create: '/api/user/create',  //注册接口
  verify: '/api/user/verify',  //验证码接口
  mobile: '/api/user/mobile',  //验证码登录接口
  passChange: '/api/user/Change',//修改密码
  Studytime: '/api/user/Studytime',//图表接口
  onlineList: '/api/online/list',//在线学习接口
  namelist: '/api/online/namelist', //我的课程
  projectAdd: '/api/projectzhc/add',//添加项目
  projectList: '/api/projectzhc/list',//项目列表
  ProjectCodeList: '/api/projectzhc/Projectlist',//创建项目查询
  comPile: '/api/projectzhc/compile',//编译项目
  preservaTion: '/api/projectzhc/Project',//保存项目
  sourceCode: '/api/project/xiazai',//下载源码
  datele: '/api/projectzhc/datele',// 删除项目接口
  update: '/api/projectzhc/update',//修改项目名称
  courseInfo: '/api/online/courseInfo',//课程接口
  match: '/api/saishi/list',//赛事接口
  CtionDetails: '/api/saishi/CtionDetails',//赛事详情  
  signup: '/api/saishi/signup',//报名接口
  completeInfo: '/api/online/completeInfo',//完成课程
  learningInfo: '/api/online/learningInfo',//我的课程看完视屏
  lineInfo: '/api/online/lineInfo',//在线学习完成时间
  FunDetails: '/api/notice/FunDetails',//公告详情
  Noticelist: '/api/notice/Noticelist',//公告列表
  CertificateList: '/api/publics/CertificateList',//证书列表
  curriculum: '/api/online/zhuanghuan'//课程列表
}

//登录接口
export function login(obj) {
  return http.post(apis.login, obj)
}

//注册接口
export function create(obj) {
  return http.post(apis.create, obj)
}

//验证码接口
export function verify(obj) {
  return http.post(apis.verify, obj)
}

//验证码登录接口
export function mobile(obj) {
  return http.post(apis.mobile, obj)
}

//修改密码
export function passChange(obj) {
  return http.post(apis.passChange, obj)
}

//图表接口
export function Studytime(obj) {
  return http.get(apis.Studytime, obj)
}

//在线学习接口
export function onlineList(obj) {
  return http.get(apis.onlineList, obj)
}

//在线学习接口
export function namelist(obj) {
  return http.get(apis.namelist, obj)
}

//添加项目
export function projectAdd(obj) {
  return http.post(apis.projectAdd, obj)
}

//项目列表
export function projectList(obj) {
  return http.post(apis.projectList, obj)
}

//创建项目查询
export function ProjectCodeList(obj) {
  return http.post(apis.ProjectCodeList, obj)
}

//编译项目
export function comPile(obj) {
  return http.post(apis.comPile, obj)
}

//保存项目
export function preservaTion(obj) {
  return http.post(apis.preservaTion, obj)
}

//下载源码
export function sourceCode(obj) {
  return http.get(apis.sourceCode, obj)
}

// 删除项目接口
export function datele(obj) {
  return http.post(apis.datele, obj)
}

//修改项目名称
export function update(obj) {
  return http.post(apis.update, obj)
}

//课程接口
export function courseInfo(obj) {
  return http.get(apis.courseInfo, obj)
}

//赛事接口
export function match(obj) {
  return http.get(apis.match, obj)
}

//赛事详情  
export function CtionDetails(obj) {
  return http.get(apis.CtionDetails, obj)
}

//报名接口
export function signup(obj) {
  return http.post(apis.signup, obj)
}

//完成课程
export function completeInfo(obj) {
  return http.get(apis.completeInfo, obj)
}

//在线课程看完视屏
export function learningInfo(obj) {
  return http.post(apis.learningInfo, obj)
}

//在线学习看完视屏
export function lineInfo(obj) {
  return http.post(apis.lineInfo, obj)
}

//公告详情
export function FunDetails(obj) {
  return http.get(apis.FunDetails, obj)
}

//公告列表
export function Noticelist(obj) {
  return http.get(apis.Noticelist, obj)
}

//公告列表
export function CertificateList(obj) {
  return http.get(apis.CertificateList, obj)
}

//课程列表
export function curriculum(obj) {
  return http.get(apis.curriculum, obj)
}