import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import axios from "axios";
Vue.prototype.axios = axios;

import VideoPlayer from 'vue-video-player/src'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

import store from "./store/index.js";

import isp from "./utils/isp"


//复制
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

//图片预览
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview)



//百度地图
import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '7xi8RI8OqinCIZvetjaawzbX3RR8Kz4c'
})

Vue.use(VideoPlayer)


// echarts
import echarts from "./echarts";
// 挂载到vue实例中
Vue.prototype.$echarts = echarts;

//引入element-ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import "./assets/global.css";

Vue.use(ElementUI);

// Vue.config.productionTip = false;

// 在此处进行配置全局的路由守卫（全局前置钩子）
// 目的是：判断当前用户中本地存储是否有token，使用户在登录之前，只能访问到/login页面，其他的页面是访问不到的。
// router.beforeEach((to, from, next) => {
//   // to 即将进入的路由
//   // from 在哪个路由进入的
//   // next 放行
//   // console.log(to); //打印的是页面要跳转到的路由,例如：它下面的path：为"/login"
//   let token = localStorage.getItem("token") || ""; //在本地存储中获取token

//   // next();
//   // return;
//   if (!token) {
//     if (to.path !== '/') {
//       next({ path: '/' })
//     }
//     else {
//       next();
//     }
//   }
//   else {
//     next();

//   }
// });


new Vue({
  router,
  store,
  isp,
  render: (h) => h(App),
}).$mount("#app");
