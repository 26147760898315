// 一. 导入axios包
import axios from 'axios';
import {
  Message, MessageBox
} from 'element-ui'
import router from "@/router";


// 二. 创建axios的对象
const http = axios.create();
http.defaults.baseURL = "" // 路径
http.defaults.timeout = 30000

let tmp = true;
// 三. 配置拦截器
http.interceptors.request.use(
  config => {
    //从本地缓存取token

    config.headers['token'] = localStorage.getItem("token");
    return config;
  },
  error => {
    Message.error({
      message: '加载超时'
    })
    return Promise.reject(error)
  });
http.interceptors.response.use(
  response => {

    // if (response.data.status === 401 && tmp) {
    //   tmp = false
    //   MessageBox.confirm("检测到异地登录,即将跳转到登录页面。", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //   })
    //     .then(() => {
    //       // Message.success('已保存当前修改')
    //       // 路由重定向跳转到登录页面
    //       router.replace({
    //         path: "/administration",
    //       });
    //       tmp = true
    //     })
    //     .catch(() => {
    //       // Message.info('当前修改未保存')
    //       // 路由重定向跳转到登录页面
    //       router.replace({
    //         path: "/administration",
    //       });
    //       tmp = true
    //     });
    // }
    return response.data;
  },
  error => {
    // Message.error(error);
    return Promise.reject(error) // 返回接口返回的错误信息
  }
);

// 四. 导出实例对象
export default {
  post(url, data) {
    return new Promise((resolve, reject) => {
      http({
        method: 'post',
        url,
        data: data,
        timeout: 30000,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json; charset=UTF-8'
        }
      }).then(res => {
        if (res) {
          resolve(res)
        }
        // resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  get(url, params) {
    return new Promise((resolve, reject) => {
      http({
        method: 'get',
        url: url,
        params, // get 请求时带的参数
        timeout: 30000,
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      }).then(res => {
        if (res) {
          resolve(res)
        }
      }).catch(err => {
        reject(err)
      })
    })
  },
}
