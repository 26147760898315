import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    redirect:'/administration',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/default.vue'),
    children: [{
      path: '/administration',
      name: 'Administration',
      component: () => import(/* webpackChunkName: "about" */ '../views/meus/Administration.vue'),
    },
    {
      path: '/article',
      name: 'Article',
      component: () => import(/* webpackChunkName: "about" */ '../views/list/Article.vue'),
    }]
  },

]

const router = new VueRouter({
  mode: 'hash',
  // base: "/history",
  routes
})

export default router
