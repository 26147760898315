<template>
  <div class="login-register" ref="vantaRef">
    <div class="contain">
      <div class="big-box" :class="{active:isLogin}">
        <div class="big-contain" key="bigContainLogin" v-if="isLogin">

          <el-form :hide-required-asterisk="true" label-width="25%" :rules="rulesWord"
            :label-position="labelPosition" ref="loginWord" :validate-on-rule-change="false"
            :model="loginWord" class="loginContainer">
            <div class="btitle">{{isCode == false ?'账户登录':'验证码登录'}}</div>
            <div v-if="!isCode">
              <!-- 用户名登录 -->
              <el-form-item prop="phoneNumber" label="登录账户:">
                <el-input type="text" auto-complete="false" v-model="loginWord.phoneNumber"
                  placeholder="请输入手机号/用户名">
                </el-input>
              </el-form-item>

              <el-form-item prop="password" class="pr" label="登录密码:">
                <el-input placeholder="请输入你的密码" v-model="loginWord.password"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')" show-password></el-input>
              </el-form-item>
            </div>

            <div v-else>
              <!-- 用户名登录 -->
              <el-form-item prop="phoneNumber" label="登录账户:">
                <el-input type="text" auto-complete="false" v-model="loginWord.phoneNumber"
                  placeholder="请输入手机号/用户名">
                </el-input>
              </el-form-item>

              <el-form-item prop="code" label="验证码:" class="pr">
                <el-input placeholder="请输入数字验证码" v-model="loginWord.code" maxlength="6"
                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')" @blur="salaryChange"></el-input>
                <button @click.prevent="getCode()" class="code-btn" :disabled="!show">
                  <span v-show="show">获取验证码</span>
                  <span v-show="!show" class="count">{{ count }} s</span>
                </button>
              </el-form-item>
            </div>

            <div class="checkboxBom">
              <el-checkbox v-model="checked" v-show="!isCode">记住我</el-checkbox>
              <div class="code" @click="codeBtn">{{isCode == false ?'验证码登录':'账号密码登录'}}</div>
            </div>
            <button class="bbutton" type="button" @click="subBtn"
              style="width: 100% ">{{isCode == false ?'账号密码登录':'验证码登录'}}</button>
            <!-- <button v-if="btnn == 2" class="bbutton" @click="submitLogin" style="width: 100% ">验证码登录</button> -->
            <div class="company">中汉易科技信息(无锡)有限公司版权所有 </div>
            <div style="text-align: center;font-size: 0.8rem;color: #666666;"><a
                style="text-decoration: none;" href="https://beian.miit.gov.cn/" target="_blank"
                rel="noopener noreferrer">苏ICP备2021024852号-1</a></div>

          </el-form>

        </div>

        <div class="big-contain" key="bigContainRegister" v-else>
          <el-form :hide-required-asterisk="true" label-width="25%" :rules="rules"
            :label-position="labelPosition" ref="loginForm" :model="loginForm" class="loginContainerList">
            <div class="btitle">创建账户</div>
            <el-form-item prop="name" label="登录昵称:">
              <el-input type="text" auto-complete="false" v-model="loginForm.name" placeholder="请输入用户昵称">
              </el-input>
            </el-form-item>

            <el-form-item prop="phone" label="登录账户:">
              <el-input type="text" auto-complete="false" v-model="loginForm.phone" placeholder="请输入手机号"
                maxlength="11">
              </el-input>
            </el-form-item>

            <el-form-item prop="code" label="验证码:" class="pr">
              <el-input placeholder="请输入数字验证码" v-model="loginForm.code" maxlength="6"
                oninput="value=value.replace(/^\.+|[^\d.]/g,'')" @blur="salaryChangeCode"></el-input>
              <button @click.prevent="getCodeData()" class="code-btn" :disabled="!showCode">
                <span v-show="showCode">获取验证码</span>
                <span v-show="!showCode" class="count">{{ counts }} s</span>
              </button>
            </el-form-item>

            <el-form-item prop="password" class="pr" label="登录密码:">
              <el-input placeholder="请输入你的密码" v-model="loginForm.password"
                oninput="value=value.replace(/^\.+|[^\d.]/g,'')" show-password></el-input>
            </el-form-item>
            <button class="bbutton" @click="register" style="width: 100% ">注册账号</button>

          </el-form>
        </div>
      </div>
      <div class="small-box" :class="{active:isLogin}">
        <div class="small-contain" key="smallContainRegister" v-if="isLogin">
          <div class="stitle">汉编工坊</div>
          <p class="scontent">最好的，不一定是最合适的；最合适的，才是真正最好的</p>
          <button class="sbutton" @click="changeType">注册</button>
        </div>
        <div class="small-contain" key="smallContainLogin" v-else>
          <div class="stitle">开始注册</div>
          <p class="scontent">和我们一起学习</p>
          <button class="sbutton" @click="changeType">登录</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { login, verify, mobile, create } from '../api/apis.js'
import * as THREE from 'three'
import Net from 'vanta/src/vanta.net'

export default {
  name: "Login",
  data() {
    return {
      isLogin: true,
      isCode: false,
      btnn: 1,
      //初始值
      show: true,
      showCode: true,
      checked: false,
      count: "",
      counts: "",
      labelPosition: "left",
      captchaUrl: "",
      loginWord: {
        phoneNumber: "",
        password: '',
        code: "",
      },
      loginForm: {
        name: "",
        phone: '',
        code: "",
        password: ''
      },
      phone: '',
      activeName: 'userName',
      // checked:true,
      rulesWord: {
        phoneNumber: [
          { required: true, message: "请输入你的手机号", trigger: "blur" },
          { min: 1, max: 11, message: "输入的手机号有误", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入你的密码", trigger: "blur" },
        ],

        code: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
      },

      rules: {
        name: [
          { required: true, message: "请输入用户昵称", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入你的手机号", trigger: "blur" },
          { min: 1, max: 11, message: "输入的手机号有误", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入你的密码", trigger: "blur" },
        ],
      },
      cities: [
        {
          value: "Wuxi",
          label: "无锡",
        },
        {
          value: "Shanghai",
          label: "上海",
        },
        {
          value: "Nanjing",
          label: "南京",
        },
        {
          value: "Chengdu",
          label: "成都",
        },
        {
          value: "Shenzhen",
          label: "深圳",
        },
        {
          value: "Guangzhou",
          label: "广州",
        },
      ],
      value: "",
    };
  },
  created() {
    this.loginWord.phoneNumber = localStorage.getItem("rem_userName");
    // localStorage.removeItem("rem_userName");
    if (this.isCode == false) {
      this.btnn = 1
    } else {
      this.btnn = 2
    }
  },
  mounted() {
    this.vantaEffect = Net({
      el: this.$refs.vantaRef,
      THREE: THREE
    })

    VANTA.NET({
      el: this.$refs.vantaRef,
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color: 0xffffff,
      backgroundColor: 0x3a89b0,
      spacing: 12.00
    })
  },
  beforeDestroy() {
    if (this.vantaEffect) {
      this.vantaEffect.destroy()
    }
  },
  methods: {
    codeBtn() {
      this.isCode = !this.isCode
      console.log(this.btnn);
      if (this.isCode == false) {
        this.btnn = 1
      } else {
        this.btnn = 2
      }

      // this.$refs['loginWord'].resetFields() // 重置form表单
    },
    changeType() {
      this.isLogin = !this.isLogin

    },

    handleClick(tab, event) {
      console.log(tab, event);
    },
    salaryChange(e) {
      this.loginWord.code = e.target.value;

    },
    salaryChangeCode(e) {
      this.loginForm.code = e.target.value;
    },
    // 获取短信验证码
    async getCode() {
      //axios请求
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;

      console.log('登录');
      if (!reg.test(this.loginWord.phoneNumber)) {
        this.$message({
          message: "请填写正确的手机号！！",
          type: "error",
          duration: 1000,
        });
        return;
      }



      // 验证码倒计时
      if (!this.timer) {
        this.count = 60;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= 60) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
        await verify({ phone: this.loginWord.phoneNumber }).then(res => {
          console.log(res, '验证码');

          if (res.status == 200) {
            this.$message({
              message: "已成功发送短信~",
              type: "success",
              duration: 800,
            });

          } else {
            this.$message({
              message: '发送失败 请稍等~',
              type: "error",
              duration: 800,
            });
          }
        })

      }
    },
    // 获取短信验证码
    async getCodeData() {
      //axios请求
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;

      console.log('注册');
      if (!reg.test(this.loginForm.phone)) {
        this.$message({
          message: "请填写正确的手机号！！",
          type: "error",
          duration: 1000,
        });
        return;
      }



      console.log(this.phone, '12312313');

      // 验证码倒计时
      if (!this.timer) {
        this.counts = 60;
        this.showCode = false;
        this.timer = setInterval(() => {
          if (this.counts > 0 && this.counts <= 60) {
            this.counts--;
          } else {
            this.showCode = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
        await verify({ phone: this.loginForm.phone }).then(res => {
          console.log(res, '验证码');

          if (res.status == 200) {
            this.$message({
              message: "已成功发送短信~",
              type: "success",
              duration: 800,
            });

          } else {
            this.$message({
              message: '发送失败 请稍等~',
              type: "error",
              duration: 800,
            });
          }
        })

      }
    },
    subBtn() {
      console.log(this.btnn, '登录 可以不可一');
      if (this.btnn == 1) {
        this.submitWord()
      } else {
        this.submitLogin()
      }
    },
    // 用户名登录
    submitWord() {
      console.log(1111111111111111111);
      setTimeout(() => {
        if (this.checked) {
          localStorage.setItem("rem_userName", this.loginWord.phoneNumber);
        }
        this.$refs.loginWord.validate(async (valid) => {
          if (!valid) {
            this.$message({
              showClose: true,
              message: "请输入所有信息哦~",
              type: "error",
            });
            // return

            console.log("error submit!!");
          } else {
            const res = await login({ tel: this.loginWord.phoneNumber, password: this.loginWord.password })

            console.log('login111111111111111111', res);
            // debugger
            if (res.status == 200) {
              localStorage.setItem('userInfo', JSON.stringify(res.data));
              localStorage.setItem('token', JSON.stringify(res.data.token));
              this.$router.push("/administration");
              this.$message({
                message: res.msg,
                type: 'success',
                duration: 800,
              });

              this.loginWord.phoneNumber = ''
              this.loginWord.password = ''
              this.loginWord.code = ''

            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
            }
          }
        });
      }, 500);

    },
    // 验证码登录
    submitLogin() {

      console.log(222222222222222222222222222);
      this.$refs.loginWord.validate(async (valid) => {
        if (valid) {

          await mobile({ phone: this.phone, code: this.loginWord.code }).then(res => {
            console.log(res, 'yanzhengma ');

            if (res.status == 200) {
              localStorage.setItem('userInfo', JSON.stringify(res.data.user));
              localStorage.setItem('token', JSON.stringify(res.data.token));
              this.$router.push("/administration");
              this.$message({
                message: res.msg,
                type: 'success',
                duration: 800,
              });
            } else {
              this.$message({
                message: res.msg,
                type: 'error',
                duration: 800,
              });
            }
          })
        } else {
          this.$message({
            showClose: true,
            message: "请输入所有信息哦~",
            type: "error",
          });
          console.log("error submit!!");
          return false;
        }
      });
    },

    //注册
    register() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          console.log(this.code, '12132132');
          await create({ nickname: this.loginForm.name, tel: this.loginForm.phone, password: this.loginForm.password, code: this.loginForm.code }).then(res => {
            console.log(res, '注册');
            if (res.status == 200) {
              this.$message({
                message: res.msg,
                type: "success",
                duration: 800
              });
              this.isLogin = true

              this.loginForm.name = ''
              this.loginForm.phone = ''
              this.loginForm.code = ''
              this.loginForm.password = ''
            } else {
              this.$message({
                message: res.msg,
                type: "error",
                duration: 800
              });
              this.isLogin = falas
            }
          })
        } else {
          this.$message({
            showClose: true,
            message: "请输入所有信息哦~",
            type: "error",
          });
          console.log("error submit!!");
          return false;
        }
      });
    },
    Nearby() {
      // const { href } = this.$router.push("Nearby");
      // window.open(href, "_blank");
    },
    // Investment() {
    //   const { href } = this.$router.push("investment");
    //   window.open(href, "_blank");
    // },
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
      if (ua.indexOf('micromessenger') > -1) {
        return true; // 是微信端

      } else {
        return false;
      }
    },

  },
};
</script>



<style lang="less" scoped>
.login-register {
  width: 100vw;
  height: 100vh;
}

/* 当屏幕宽度小于等于768px时应用的样式 */
@media screen and (max-width: 768px) {
  .contain {
    width: 680px;
    height: 500px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 20px;
    // box-shadow: 0 0 3px #f0f0f0, 0 0 6px #f0f0f0;
  }
  .big-box {
    width: 65%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 35%;
    transform: translateX(0%);
    transition: all 1s;
  }
  .big-contain {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loginContainer {
    border-radius: 15px;
    background-clip: padding-box;
    margin: 50px auto;
    width: 80%;
    height: 100%;
    padding: 8px 20px;

    .btitle {
      font-size: 1.5em;
      font-weight: bold;
      color: rgb(57, 167, 176);
      margin: 10px 0;
      text-align: center;
    }

    .pr {
      position: relative;
    }

    .code-btn {
      width: 100px;
      height: 20px;
      position: absolute;
      top: 10px;
      right: 5px;
      z-index: 222;
      color: #ef8466;
      font-size: 14px;
      border: none;
      border-left: 1px solid #dcdfe6;
      padding-left: 10px;
      background-color: #fff;
      cursor: pointer;
    }

    .company {
      margin: 50px 0 0 0;
      text-align: center;
      font-size: 0.8rem;
      color: #666666;
    }

    .checkboxBom {
      display: flex;
      justify-content: space-between;
      margin-top: -8px;
      padding-bottom: 15px;
    }

    .code {
      font-size: 14px;
      color: #606266;
      width: 100%;
      text-align: right;
      cursor: pointer;
      &:focus {
        color: #4d85ff;

        outline: 0px;
      }
      &:hover {
        color: #4d85ff;
      }
    }

    .bbutton {
      width: 20%;
      height: 40px;
      border-radius: 24px;
      border: none;
      outline: none;
      background-color: rgb(57, 167, 176);
      color: #fff;
      font-size: 0.9em;
      cursor: pointer;
    }
  }
  .loginContainerList {
    border-radius: 15px;
    background-clip: padding-box;
    margin: 20px auto;
    width: 80%;
    height: 100%;
    padding: 8px 30px;
    // background: #fff;

    .btitle {
      font-size: 1.5em;
      font-weight: bold;
      color: rgb(57, 167, 176);
      margin: 20px 0;
      text-align: center;
    }

    .pr {
      position: relative;
    }

    .code-btn {
      width: 100px;
      height: 20px;
      position: absolute;
      top: 10px;
      right: 5px;
      z-index: 222;
      color: #ef8466;
      font-size: 14px;
      border: none;
      border-left: 1px solid #dcdfe6;
      padding-left: 10px;
      background-color: #fff;
      cursor: pointer;
    }

    .company {
      margin: 50px 0 0 0;
      text-align: center;
      font-size: 0.8rem;
      color: #666666;
    }

    .checkboxBom {
      display: flex;
      justify-content: space-between;
      margin-top: -8px;
      padding-bottom: 15px;
    }

    .code {
      font-size: 14px;
      color: #606266;
      width: 100%;
      text-align: right;
      cursor: pointer;
      &:focus {
        color: #4d85ff;

        outline: 0px;
      }
      &:hover {
        color: #4d85ff;
      }
    }

    .bbutton {
      width: 20%;
      height: 40px;
      border-radius: 24px;
      border: none;
      outline: none;
      background-color: rgb(0, 0, 0);
      color: #fff;
      font-size: 0.9em;
      cursor: pointer;
    }
  }
  .small-box {
    width: 35%;
    height: 100%;
    // background: linear-gradient(135deg, rgb(57, 167, 176), rgb(56, 183, 145));
    background: linear-gradient(135deg, #d7d0d0, #3a89b0);
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(0%);
    transition: all 1s;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
  }
  .small-contain {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .stitle {
    font-size: 1.5em;
    font-weight: bold;
    color: #fff;
  }
  .scontent {
    font-size: 0.8em;
    color: #fff;
    text-align: center;
    padding: 2em 4em;
    line-height: 1.7em;
  }
  .sbutton {
    width: 60%;
    height: 40px;
    border-radius: 24px;
    border: 1px solid #fff;
    outline: none;
    background-color: transparent;
    color: #fff;
    font-size: 0.9em;
    cursor: pointer;
  }

  .big-box.active {
    left: 0;
    transition: all 0.5s;
  }
  .small-box.active {
    left: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    transform: translateX(-100%);
    transition: all 1s;
  }
}

/* 当屏幕宽度在768px和1024px之间时应用的样式 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contain {
    width: 680px;
    height: 500px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 20px;
    // box-shadow: 0 0 3px #f0f0f0, 0 0 6px #f0f0f0;
  }
  .big-box {
    width: 65%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 35%;
    transform: translateX(0%);
    transition: all 1s;
  }
  .big-contain {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loginContainer {
    border-radius: 15px;
    background-clip: padding-box;
    margin: 50px auto;
    width: 80%;
    height: 100%;
    padding: 8px 20px;

    .btitle {
      font-size: 1.5em;
      font-weight: bold;
      color: rgb(57, 167, 176);
      margin: 10px 0;
      text-align: center;
    }

    .pr {
      position: relative;
    }

    .code-btn {
      width: 100px;
      height: 20px;
      position: absolute;
      top: 10px;
      right: 5px;
      z-index: 222;
      color: #ef8466;
      font-size: 14px;
      border: none;
      border-left: 1px solid #dcdfe6;
      padding-left: 10px;
      background-color: #fff;
      cursor: pointer;
    }

    .company {
      margin: 50px 0 0 0;
      text-align: center;
      font-size: 0.8rem;
      color: #666666;
    }

    .checkboxBom {
      display: flex;
      justify-content: space-between;
      margin-top: -8px;
      padding-bottom: 15px;
    }

    .code {
      font-size: 14px;
      color: #606266;
      width: 100%;
      text-align: right;
      cursor: pointer;
      &:focus {
        color: #4d85ff;

        outline: 0px;
      }
      &:hover {
        color: #4d85ff;
      }
    }

    .bbutton {
      width: 20%;
      height: 40px;
      border-radius: 24px;
      border: none;
      outline: none;
      background-color: rgb(57, 167, 176);
      color: #fff;
      font-size: 0.9em;
      cursor: pointer;
    }
  }
  .loginContainerList {
    border-radius: 15px;
    background-clip: padding-box;
    margin: 20px auto;
    width: 80%;
    height: 100%;
    padding: 8px 30px;
    // background: #fff;

    .btitle {
      font-size: 1.5em;
      font-weight: bold;
      color: rgb(57, 167, 176);
      margin: 20px 0;
      text-align: center;
    }

    .pr {
      position: relative;
    }

    .code-btn {
      width: 100px;
      height: 20px;
      position: absolute;
      top: 10px;
      right: 5px;
      z-index: 222;
      color: #ef8466;
      font-size: 14px;
      border: none;
      border-left: 1px solid #dcdfe6;
      padding-left: 10px;
      background-color: #fff;
      cursor: pointer;
    }

    .company {
      margin: 50px 0 0 0;
      text-align: center;
      font-size: 0.8rem;
      color: #666666;
    }

    .checkboxBom {
      display: flex;
      justify-content: space-between;
      margin-top: -8px;
      padding-bottom: 15px;
    }

    .code {
      font-size: 14px;
      color: #606266;
      width: 100%;
      text-align: right;
      cursor: pointer;
      &:focus {
        color: #4d85ff;

        outline: 0px;
      }
      &:hover {
        color: #4d85ff;
      }
    }

    .bbutton {
      width: 20%;
      height: 40px;
      border-radius: 24px;
      border: none;
      outline: none;
      background-color: rgb(57, 167, 176);
      color: #fff;
      font-size: 0.9em;
      cursor: pointer;
    }
  }
  .small-box {
    width: 35%;
    height: 100%;
    background: linear-gradient(135deg, #d7d0d0, #3a89b0);
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(0%);
    transition: all 1s;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
  }
  .small-contain {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .stitle {
    font-size: 1.5em;
    font-weight: bold;
    color: #fff;
  }
  .scontent {
    font-size: 0.8em;
    color: #fff;
    text-align: center;
    padding: 2em 4em;
    line-height: 1.7em;
  }
  .sbutton {
    width: 60%;
    height: 40px;
    border-radius: 24px;
    border: 1px solid #fff;
    outline: none;
    background-color: transparent;
    color: #fff;
    font-size: 0.9em;
    cursor: pointer;
  }

  .big-box.active {
    left: 0;
    transition: all 0.5s;
  }
  .small-box.active {
    left: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    transform: translateX(-100%);
    transition: all 1s;
  }
}

/* 当屏幕宽度大于等于1024px时应用的样式 */
@media screen and (min-width: 1024px) {
  .contain {
    width: 700px;
    height: 450px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 20px;
    // box-shadow: 0 0 3px #f0f0f0, 0 0 6px #f0f0f0;
  }
  .big-box {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 35%;
    transform: translateX(0%);
    transition: all 1s;
  }
  .big-contain {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .loginContainer {
    border-radius: 15px;
    background-clip: padding-box;
    margin: 50px auto;
    width: 100%;
    height: 100%;
    padding: 8px 50px;
    // background: #fff;

    /deep/ .el-form-item {
      margin: 20px 0;
    }

    .btitle {
      font-size: 1.5em;
      font-weight: bold;
      color: rgb(57, 167, 176);
      margin: 5px 0;
      text-align: center;
    }

    .pr {
      position: relative;
    }

    .code-btn {
      width: 100px;
      height: 20px;
      position: absolute;
      top: 10px;
      right: 5px;
      z-index: 222;
      color: #ef8466;
      font-size: 14px;
      border: none;
      border-left: 1px solid #dcdfe6;
      padding-left: 10px;
      background-color: #fff;
      cursor: pointer;
    }

    .company {
      margin: 50px 0 0 0;
      text-align: center;
      font-size: 0.8rem;
      color: #666666;
      word-break: keep-all;
      white-space: nowrap;
    }

    .checkboxBom {
      display: flex;
      justify-content: space-between;
      margin-top: -8px;
      padding-bottom: 15px;
    }

    .code {
      font-size: 14px;
      color: #606266;
      width: 100%;
      text-align: right;
      cursor: pointer;
      &:focus {
        color: #4d85ff;

        outline: 0px;
      }
      &:hover {
        color: #4d85ff;
      }
    }

    .bbutton {
      width: 20%;
      height: 40px;
      border-radius: 24px;
      border: none;
      outline: none;
      background-color: rgb(57, 167, 176);
      color: #fff;
      font-size: 0.9em;
      cursor: pointer;
    }
  }

  .loginContainerList {
    border-radius: 15px;
    background-clip: padding-box;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    padding: 8px 50px;
    // background: #fff;

    .btitle {
      font-size: 1.5em;
      font-weight: bold;
      color: rgb(57, 167, 176);
      margin: 20px 0;
      text-align: center;
    }

    .pr {
      position: relative;
    }

    .code-btn {
      width: 100px;
      height: 20px;
      position: absolute;
      top: 10px;
      right: 5px;
      z-index: 222;
      color: #ef8466;
      font-size: 14px;
      border: none;
      border-left: 1px solid #dcdfe6;
      padding-left: 10px;
      background-color: #fff;
      cursor: pointer;
    }

    .company {
      margin: 50px 0 0 0;
      text-align: center;
      font-size: 0.8rem;
      color: #666666;
    }

    .checkboxBom {
      display: flex;
      justify-content: space-between;
      margin-top: -8px;
      padding-bottom: 15px;
    }

    .code {
      font-size: 14px;
      color: #606266;
      width: 100%;
      text-align: right;
      cursor: pointer;
      &:focus {
        color: #4d85ff;

        outline: 0px;
      }
      &:hover {
        color: #4d85ff;
      }
    }

    .bbutton {
      width: 20%;
      height: 40px;
      border-radius: 24px;
      border: none;
      outline: none;
      background-color: rgb(57, 167, 176);
      color: #fff;
      font-size: 0.9em;
      cursor: pointer;
    }
  }

  .small-box {
    width: 35%;
    height: 100%;
    background: linear-gradient(135deg, #d7d0d0, #3a89b0);
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(0%);
    transition: all 1s;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
  }
  .small-contain {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .stitle {
    font-size: 1.5em;
    font-weight: bold;
    color: #fff;
  }
  .scontent {
    font-size: 0.8em;
    color: #fff;
    text-align: center;
    padding: 2em 4em;
    line-height: 1.7em;
  }
  .sbutton {
    width: 60%;
    height: 40px;
    border-radius: 24px;
    border: 1px solid #fff;
    outline: none;
    background-color: transparent;
    color: #fff;
    font-size: 0.9em;
    cursor: pointer;
  }

  .big-box.active {
    left: 0;
    transition: all 0.5s;
  }
  .small-box.active {
    left: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    transform: translateX(-100%);
    transition: all 1s;
  }
}
</style>
